import React from "react"
import Layout from "../components/layout.js"
import Button from "../components/button.js"
import { Helmet } from "react-helmet"
import Meta from "../components/meta.js"
import UncontrolledLottie from "../components/lottiething.js"
import * as styles from "./index.module.css"
import video from "../../data/animation/gay_2.mp4"
import Form from "../components/emailForm.js"
import Link from "gatsby-link"
import Discord from "../components/discord.js"

export default function Home() {
  return (
    <Layout style={{ height: `100%` }}>
      <Meta></Meta>{" "}
      <div className={styles.main}>
        <div className={styles.textArea}>
          <h1
            style={{
              fontWeight: `700`,
              fontSize: `4.5rem`,
              paddingTop: `2rem`,
            }}
          >
            <mark style={{ color: `black`, fontWeight: `700` }}>
            <span style={{ color: `#f26227` }}>en</span>
              COЯE
              
            </mark>
          </h1>
          <p
            style={{
              marginTop: `8px`,
              fontSize: `1.4rem`,
              fontWeight: `700`,
            }}
          >
            {" "}
            Training Camp Live!
          </p>
          <p style={{ marginTop: `25px`, fontSize: `1.1rem` }}>
            No more wait for{" "}
            <span style={{ fontWeight: `600` }}>Obsession</span>
            <sup>∞</sup>.
            <br />
            <br />
            Delhi Public School, Dwarka's tech society, Computer Obsessed Radical Enthusiasts is actively recruiting!.
            <br />
            <br />
            From gaming to design, hacking to music production - say hello to
            one of the most anticipated school events of the year.
            <br />
            <br />
            <span style={{ fontWeight: `700` }}>Register for the training camp now!</span>
          </p>
          <p></p>
          <div
            style={{
              marginTop: `45px`,
              display: `flex`,
              justifyContent: `baseline`,
            }}
          >
            <Link to="/register" style={{}}>
              <Button style={{}} className={styles.chutiya}>Register</Button>
            </Link>
            <a href="https://coreisus.co/discord">
              <button className={styles.chutiya2}>
                <div>
                  <img
                    src="https://img.icons8.com/fluent/48/000000/discord-logo.png"
                    width="20px"
                  />
                  <span>Join Discord</span>{" "}
                </div>{" "}
              </button>
            </a>
          </div>
        </div>
        <div style={{ marginTop: `` }}>
              
           {<UncontrolledLottie className={styles.anim}></UncontrolledLottie>} 
          {/*<video
            width="400"
            height="400"
            className="videoTag"
            autoPlay
            loop
            muted
          >
            <source src={video} type="video/mp4" />
          </video>*/}
        </div>
      </div>
    </Layout>
  )
}
